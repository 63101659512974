import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  ArticleDate,
} from "../../styledComponents/article"
import Layout from "../../components/layout"
import {
  Section,
  SectionHeading,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo
} from "../../styledComponents/videoTestimonial"
import EventList from "../../components/EventList"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const PermissioingHistoryVideo = ({ data, location }) => {
  

  return (
    <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />x
      </Helmet>
      <Layout displayNavBorder location={location} hideNewsletterSignUp>
        <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
          <StyledVideoTestimonialHeader>
            <h1 itemProp="headline">How did Permissioning™ start?</h1>
            <ArticleDate offScreen dateTime=''>Last updated on June 10th 2023</ArticleDate>
          </StyledVideoTestimonialHeader>
          <StyledVideoTestimonialVideo>
            <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
              <div class="wp-block-embed__wrapper">
                <iframe loading="lazy" title="Karina Grant talking with Kaye Lee the founder of Permissioning & Transformation" width="640" height="360" src="https://www.youtube.com/embed/7T4tt1yY0OE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
              </div>
            </figure>
          </StyledVideoTestimonialVideo>
        </StyledVideoTestimonialArticle>

        
      </Layout>
    </>
  )
}

export default PermissioingHistoryVideo

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
      

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
